import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DtfooterComponent } from '../_components/dtfooter/dtfooter.component';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    IonicModule,
  ],

  declarations: [DtfooterComponent
  ],

  exports: [DtfooterComponent],
})
export class SharedModule { }

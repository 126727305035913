import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GV } from '../globalVariable';
import { LoadingController, NavController } from '@ionic/angular';
import { ENUMS } from '../ENUMS';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient,
        private loadingCtrl: LoadingController,
        private navCtrl: NavController) { }

    // getToken(code: string): Observable<any> {
    //     const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    //     const body = new URLSearchParams();
    //     body.set('client_id', environment.clientId);
    //     body.set('code', code);
    //     body.set('grant_type', 'authorization_code');
    //     body.set('redirect_uri', environment.redirectUri);
    //     return this.http.post(environment.keycloakTokenUrl, body.toString(), { headers, observe: 'response' });
    // }

    getToken(email, password): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new URLSearchParams();
        body.set('client_id', environment.clientId);
        body.set('username', email);
        body.set('password', password);
        body.set('grant_type', 'password');
        body.set('client_secret', environment.clientSecret);
        return this.http.post(environment.keycloakTokenUrl, body.toString(), { headers, observe: 'response' });
    }

    logoutApi(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new URLSearchParams();
        body.set('client_id', environment.clientId);
        body.set('client_secret', environment.clientSecret);
        body.set('refresh_token', GV.refreshToken)
        return this.http.post(environment.LogoutUrl, body.toString(), { headers, observe: 'response' });
    }

    async logout() {
        if (GV.refreshToken) {
            this.logoutApi().subscribe(
                response => {
                    GV.isSession = 'false';
                    localStorage.setItem('isSession', 'false')
                    localStorage.setItem('accessToken', '')
                    localStorage.setItem('refreshToken', '')
                    this.navCtrl.navigateRoot('/login')
                },
                error => {
                }
            );
        } else {
            this.navCtrl.navigateRoot('/login')
        }
    }
}

export const ENUMS = {
    LoaderConfig: {
        message: "",
        cssClass: "custom-loading",
        translucent: false,
        dismissOnPageChange: true,
        enableBackdropDismiss: false,
        showBackdrop: true,
        keyboardClose: true,
    },
    URLS: {
        /** URL Resource Paths */
        microservice: "/marvelgate/apis/v1/microservices",
        getUserDetails: "/marvelgate/apis/v1/users/sync",
        configurations: "/marvelgate/apis/v1/configurations",
    },
    ITEMS_PER_PAGE: 8
}
import { Component, Input } from '@angular/core';
import { UtilityService } from 'src/app/_service/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public utilityService: UtilityService,
  ) { }
}

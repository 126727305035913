export const GV = {
    accessToken: "",
    refreshToken: "",
    isSession: 'false',
    dontRepeat: 'false',
    CreatedOn: "",
    Email: "",
    FirstName: "",
    LastName: "",
    UpdatedAt: "",
}
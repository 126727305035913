// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: linear-gradient(45deg, var(--ion-color-primary), var(--ion-color-secondary)) !important;
}

.white-images {
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHFHQUFBO0FBQ0o7O0FBRUE7RUFFSSxxQkFBQTtFQUNBLCtCQUFBO0FBQ0oiLCJmaWxlIjoiaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLXRvb2xiYXIge1xuICAgIC0tYmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KDQ1ZGVnLCB2YXIoLS1pb24tY29sb3ItcHJpbWFyeSksIHZhcigtLWlvbi1jb2xvci1zZWNvbmRhcnkpKSAhaW1wb3J0YW50O1xufVxuXG4ud2hpdGUtaW1hZ2VzIHtcbiAgICAtd2Via2l0LWZpbHRlcjogYnJpZ2h0bmVzcygwKSBpbnZlcnQoMSk7XG4gICAgLyogU2FmYXJpIDYuMCAtIDkuMCAqL1xuICAgIGZpbHRlcjogYnJpZ2h0bmVzcygwKSBpbnZlcnQoMSk7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/_components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,qGAAA;AACJ;;AAEA;EAEI,qBAAA;EACA,+BAAA;AACJ;AAAA,4nBAA4nB","sourcesContent":["ion-toolbar {\n    --background: linear-gradient(45deg, var(--ion-color-primary), var(--ion-color-secondary)) !important;\n}\n\n.white-images {\n    -webkit-filter: brightness(0) invert(1);\n    /* Safari 6.0 - 9.0 */\n    filter: brightness(0) invert(1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

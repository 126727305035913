// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  overflow: hidden;
  background: var(--ion-color-white);
  height: 70px;
  border-bottom: 0px solid var(--ion-color-light);
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-primary), var(--ion-color-secondary)) !important;
}

.content {
  margin-top: 70px;
  margin-left: 200px;
  transition: 0s;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGtCQUFBO0VBQ0EsYUFBQTtFQUNBLFdBQUE7RUFDQSxvQkFBQTtFQUNBLG1CQUFBO0VBQ0EsZ0JBQUE7RUFDQSxrQ0FBQTtFQUNBLFlBQUE7RUFDQSwrQ0FBQTtBQUNKOztBQUVBO0VBQ0ksc0dBQUE7QUFDSjs7QUFFQTtFQUNJLGdCQUFBO0VBQ0Esa0JBQUE7RUFDQSxjQUFBO0FBQ0oiLCJmaWxlIjoiYXBwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmhlYWRlciB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAganVzdGlmeS1jb250ZW50OiBlbmQ7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgIGJhY2tncm91bmQ6IHZhcigtLWlvbi1jb2xvci13aGl0ZSk7XG4gICAgaGVpZ2h0OiA3MHB4O1xuICAgIGJvcmRlci1ib3R0b206IDBweCBzb2xpZCB2YXIoLS1pb24tY29sb3ItbGlnaHQpO1xufVxuXG5pb24tY29udGVudCB7XG4gICAgLS1iYWNrZ3JvdW5kOiBsaW5lYXItZ3JhZGllbnQoMTgwZGVnLCB2YXIoLS1pb24tY29sb3ItcHJpbWFyeSksIHZhcigtLWlvbi1jb2xvci1zZWNvbmRhcnkpKSAhaW1wb3J0YW50O1xufVxuXG4uY29udGVudCB7XG4gICAgbWFyZ2luLXRvcDogNzBweDtcbiAgICBtYXJnaW4tbGVmdDogMjAwcHg7XG4gICAgdHJhbnNpdGlvbjogMHM7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kCAAA;EACA,YAAA;EACA,+CAAA;AACJ;;AAEA;EACI,sGAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,cAAA;AACJ;AACA,wjCAAwjC","sourcesContent":[".header {\n    position: absolute;\n    display: flex;\n    width: 100%;\n    justify-content: end;\n    align-items: center;\n    overflow: hidden;\n    background: var(--ion-color-white);\n    height: 70px;\n    border-bottom: 0px solid var(--ion-color-light);\n}\n\nion-content {\n    --background: linear-gradient(180deg, var(--ion-color-primary), var(--ion-color-secondary)) !important;\n}\n\n.content {\n    margin-top: 70px;\n    margin-left: 200px;\n    transition: 0s;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ENUMS } from 'src/app/ENUMS';
import { UtilityService } from 'src/app/_service/utility.service';
@Component({
  selector: 'dtfooter',
  templateUrl: './dtfooter.component.html',
  styleUrls: ['./dtfooter.component.scss'],
})
export class DtfooterComponent implements OnInit {

  itemsPerPage: number = ENUMS.ITEMS_PER_PAGE

  @Input() page: any;
  @Input() objLength: any;
  @Input() totalPages: any;
  @Input() fromPage: any;
  @Input() toPage: any;

  @Output() prevPage: EventEmitter<number> = new EventEmitter<any>();
  @Output() nextPage: EventEmitter<number> = new EventEmitter<any>();
  @Output() rootPage: EventEmitter<void> = new EventEmitter<any>();

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnInit() { }

  moveToPrevPage(numberOfPages: number | undefined) {
    this.prevPage.emit(numberOfPages)
  }

  moveToNextPage(numberOfPages: number | undefined) {
    this.nextPage.emit(numberOfPages)
  }
  moveToRootPage() {
    this.rootPage.emit()
  }

}

import { Component } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { AuthService } from './_service/keycloakAuth.service';
import { GV } from './globalVariable';
import { UtilityService } from './_service/utility.service';
import { ENUMS } from './ENUMS';
import { ApiService } from './_service/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loading: boolean = false;
  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
    public utilityService: UtilityService,
    private apiService: ApiService,
    private loadingCtrl: LoadingController
  ) {
    let url = window.location.href.replace('/?', '?')
    this.utilityService.showMenu = true
    let isSession = localStorage.getItem("isSession")
    if (isSession !== null) { GV.isSession = isSession } else { GV.isSession = 'false' }
    if (GV.isSession == 'true') {
      let accessToken = localStorage.getItem('accessToken')
      if (accessToken) { GV.accessToken = accessToken }
      let refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) { GV.refreshToken = refreshToken }
      this.getUserDetails()
      this.navCtrl.navigateRoot('/home')
      return
    } else {
      this.utilityService.showMenu = false
      this.authService.logout()
      return
    }
  }

  // async getToken(code: string) {
  //   if (code) {
  //     let ld = await this.loadingCtrl.create(ENUMS.LoaderConfig);
  //     ld.present().then(() => {
  //       this.authService.getToken(code).subscribe(
  //         response => {
  //           localStorage.setItem('accessToken', response.body.access_token); localStorage.setItem('isSession', 'true')
  //           let accessToken = localStorage.getItem('accessToken'); if (accessToken) { GV.accessToken = accessToken; GV.isSession = 'true'; this.getUserDetails() }
  //           ld.dismiss();
  //           this.navCtrl.navigateRoot('/home')
  //         },
  //         error => {
  //           ld.dismiss();
  //           this.utilityService.showErrMsgTop('Session timed out! Please login again')
  //           this.utilityService.showMenu = false
  //           this.authService.logout()
  //         }
  //       );
  //     });
  //     ld.dismiss();
  //   }
  // }

  async getUserDetails() {
    let ld = await this.loadingCtrl.create(ENUMS.LoaderConfig);
    ld.present().then(() => {
      this.apiService.getUserDetails(GV.accessToken, environment.API_END_POINT + ENUMS.URLS.getUserDetails).subscribe(
        response => {
          this.utilityService.storeLoginResponse(response.body.data)
          ld.dismiss();
        },
        error => {
          ld.dismiss();
          this.utilityService.showErrMsgTop('Session timed out! Please login again')
          this.utilityService.showMenu = false
          this.authService.logout()
        }
      );
    });
  }
}
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UtilityService } from 'src/app/_service/utility.service';

@Component({
  selector: 'app-rejectmodal',
  templateUrl: './rejectmodal.page.html',
  styleUrls: ['./rejectmodal.page.scss'],
})
export class RejectmodalPage {

  remarkForm: any;
  remarkErr: boolean = false;
  loading: boolean = false;

  @ViewChild('remarkCtrl', { static: false }) remarkCtrl: any;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    public utilityService: UtilityService,
  ) { this.createForm() }

  goBack() {
    this.modalCtrl.dismiss();
  }

  createForm() {
    this.remarkForm = this.fb.group({
      remark: [''],
    })
  }

  validateRemark(ev: any) {
    this.remarkErr = false
  }

  onSubmit() {
    if (!this.remarkForm.value.remark) {
      this.remarkErr = true
      this.remarkCtrl.el.children[0].focus();
      return
    }
    this.modalCtrl.dismiss(this.remarkForm.value.remark);
  }
}


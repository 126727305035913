import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ENUMS } from 'src/app/ENUMS';
import { ApiService } from 'src/app/_service/api.service';
import { AuthService } from 'src/app/_service/keycloakAuth.service';
import { UtilityService } from 'src/app/_service/utility.service';
import { GV } from 'src/app/globalVariable';

@Component({
  selector: 'app-create-microservice',
  templateUrl: './create-microservice.page.html',
  styleUrls: ['./create-microservice.page.scss'],
})
export class CreateMicroservicePage {
  microserviceForm: any;
  nameErr: boolean = false;
  descriptionErr: boolean = false;
  loading: boolean = false;
  type: string = "";
  microservice: any;

  @ViewChild('nameCtrl', { static: false }) nameCtrl: any;
  @ViewChild('descriptionCtrl', { static: false }) descriptionCtrl: any;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private navParams: NavParams,
    public utilityService: UtilityService,
    private loadingCtrl: LoadingController,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.microservice = this.navParams.get('microservice');
    this.type = this.navParams.get('type');
    this.createForm()
  }

  goBack() {
    this.utilityService.mainMenu = "home"
    this.modalCtrl.dismiss('noChange');
  }

  createForm() {
    this.microserviceForm = this.fb.group({
      name: [''],
      description: ['']
    })
    if (this.type == 'update') {
      this.microserviceForm.patchValue({
        name: this.microservice.name,
        description: this.microservice.description
      })
    }
  }

  validateName(ev: any) {
    this.nameErr = false
  }

  validateDescription(ev: any) {
    this.descriptionErr = false
  }

  onSubmit() {
    this.nameErr = false
    this.descriptionErr = false
    if (!this.microserviceForm.value.name) {
      this.nameErr = true
      this.nameCtrl.el.children[0].focus();
      return
    }
    if (!this.microserviceForm.value.description) {
      this.descriptionErr = true
      this.descriptionCtrl.el.children[0].focus();
      return
    }
   
    this.createUpdateMicroservice()
  }

  // create/update microservice api call

  async createUpdateMicroservice() {
    let reqBody = {}
    if (this.type == 'create') {
      reqBody = {
        microservice_name: this.microserviceForm.value.name,
        description: this.microserviceForm.value.description,
        entity_name: this.utilityService.Entity
      }
    } else {
      reqBody = {
        description: this.microserviceForm.value.description,
        entity_name: this.utilityService.Entity
      }
    }
    this.loading = true;
    let ld = await this.loadingCtrl.create(ENUMS.LoaderConfig);
    ld.present().then(() => {
      this.apiService.microservice(reqBody, this.microservice?.id)
        .then((data: { data: any; }) => {
          const response = JSON.parse(data.data)
          if (response.status !== 'success') {
            this.loading = false;
            this.utilityService.showErrMsgTop(response.Message);
            return
          }
          this.utilityService.mainMenu = "home"
          this.utilityService.showSuccessMsgTop(response.message);
          ld.dismiss();
          this.loading = false;
          this.modalCtrl.dismiss();
          return null
        }, (err: any) => {
          ld.dismiss();
          this.loading = false;
          let error = JSON.parse(err.data)
          if (error?.error?.errors?.errorCode == "err-auth-token-is-invalid") {
            this.utilityService.showErrMsgTop('Session timed out! Please login again')
            this.utilityService.showMenu = false
            this.authService.logout()
            return
          }
          this.utilityService.showErrMsgTop(error?.error?.errors?.errorCode)
        });
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, delay, lastValueFrom, of, retryWhen, scan, switchMap, takeWhile } from 'rxjs';
import { ENUMS } from '../ENUMS';
import { GV } from '../globalVariable';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: "root",
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) { }

  getUserDetails(code: string, url: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + code,
      'Content-Type': 'application/json',
    });
    return this.httpClient.post(url, null, { headers, observe: 'response', withCredentials: false });
  }

  httpPost(Url?: string, body?: object): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + GV.accessToken,
      'Content-Type': 'application/json',
      'entity-name': this.utilityService.Entity
    });

    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .post(environment.API_END_POINT + Url, body, { headers, withCredentials: false })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        })
        .catch(err => {
          reject(
            { data: JSON.stringify(err) }
          )
        })
    });
  }


  httpPut(Url: string, body: object): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + GV.accessToken,
      'Content-Type': 'application/json',
      'entity-name': this.utilityService.Entity
    });

    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .put(environment.API_END_POINT + Url, body, { headers, withCredentials: false })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        })
        .catch(err => {
          reject(
            { data: JSON.stringify(err) }
          )
        })
    });
  }

  httpGet(Url: string): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + GV.accessToken,
      'Content-Type': 'application/json',
      'entity-name': this.utilityService.Entity
    });

    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .get(environment.API_END_POINT + Url, { headers, withCredentials: false })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        })
        .catch(err => {
          reject(
            { data: JSON.stringify(err) }
          )
        })
    });
  }

  httpPatch(Url: string, body?: object): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + GV.accessToken,
      'Content-Type': 'application/json',
      'entity-name': this.utilityService.Entity
    });

    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .patch(environment.API_END_POINT + Url, body, { headers, withCredentials: false })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        })
        .catch(err => {
          reject(
            { data: JSON.stringify(err) }
          )
        })
    });
  }

  httpToken(): Promise<any> {
    return new Promise((resolve) => {
      const observable = this.httpClient
        .get(environment.API_END_POINT, { withCredentials: true })
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        });
    });
  }

  getToken() {
    this.httpToken();
  }

  microservice(body: any, id?: string): Promise<any> {
    const url = id ? `${ENUMS.URLS.microservice}/${id}` : ENUMS.URLS.microservice;
    return id ? this.httpPut(url, body) : this.httpPost(url, body);
  }

  enableDisableMS(body: any, id: any): Promise<any> {
    return this.httpPatch(`${ENUMS.URLS.microservice}/${id}/status`, body);
  }

  configuration(body: any, id?: string): Promise<any> {
    const url = id ? `${ENUMS.URLS.configurations}/${id}` : ENUMS.URLS.configurations;
    return id ? this.httpPut(url, body) : this.httpPost(url, body);
  }

  microserviceList(): Promise<any> {
    return this.httpGet(ENUMS.URLS.microservice);
  }

  microserviceCount(): Promise<any> {
    return this.httpGet(`${ENUMS.URLS.microservice}/summaries`);
  }

  microserviceDetail(id: any): Promise<any> {
    return this.httpGet(`${ENUMS.URLS.microservice}/${id}`);
  }

  Endpoints(id: any): Promise<any> {
    return this.httpGet(`${ENUMS.URLS.microservice}/${id}/configurations`);
  }

  endpointDetails(id: any): Promise<any> {
    return this.httpGet(`${ENUMS.URLS.configurations}/${id}`);
  }

  enableDisableConf(body: any, id: any): Promise<any> {
    return this.httpPatch(`${ENUMS.URLS.configurations}/${id}/status`, body);
  }

  authorize(body: any, id: any): Promise<any> {
    return this.httpPost(`${ENUMS.URLS.configurations}/${id}/approval`, body);
  }

  publish(id: any): Promise<any> {
    return this.httpPost(`${ENUMS.URLS.microservice}/${id}/publish`);
  }

  microserviceReport(startDate?: string, endDate?: string, status?: string): Promise<any> {
    const url = `/marvelgate/apis/v1/microservices?fromDate=${startDate}&toDate=${endDate}&status=${status}`;
    return this.httpGet(url);
  }

  microserviceForPublish(status?: string, isPublished?: boolean): Promise<any> {
    const url = `/marvelgate/apis/v1/microservices?apiApprovalStatus=${status}&isApiPublished=${isPublished}`;
    return this.httpGet(url);
  }
  endpointReport(startDate?: string, endDate?: string): Promise<any> {
    const url = `/marvelgate/apis/v1/configurations?fromDate=${startDate}&toDate=${endDate}`;
    return this.httpGet(url);
  }
  pendingEndpoints(): Promise<any> {
    const url = `/marvelgate/apis/v1/configurations?apiApprovalStatus=PENDING`;
    return this.httpGet(url);
  }

  auditReport(startDate?: string, endDate?: string): Promise<any> {
    const url = `/marvelgate/apis/v1/audits?fromDate=${startDate}&toDate=${endDate}`;
    return this.httpGet(url);
  }

  getJSON(id?: any): Promise<any> {
    return this.httpGet(`${ENUMS.URLS.microservice}/${id}/publish/templates`);
  }

}
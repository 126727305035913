import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GV } from '../globalVariable';
import { ENUMS } from '../ENUMS';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  mainMenu = '';
  showMenu = false
  UserType: string = ""
  Entity: any;
  constructor(private toastCtrl: ToastController) { }

  async showErrMsgTop(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 10000,
      header: "",
      position: "top",
      cssClass: "custom-toast-message font-bold",
      color: "danger",
      buttons: [
        {
          text: "OK",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    toast.present();
  }

  async showSuccessMsgTop(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 10000,
      position: "top",
      cssClass: "custom-toast-message font-bold",
      color: "success",
      buttons: [
        {
          text: "OK",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    toast.present();
  }

  storeLoginResponse(response: any) {
    GV.CreatedOn = response.hasOwnProperty('created_at') ? response.created_at : ''
    GV.Email = response.hasOwnProperty('email') ? response.email : ''
    GV.FirstName = response.hasOwnProperty('first_name') ? response.first_name : ''
    GV.LastName = response.hasOwnProperty('last_name') ? response.last_name : ''
    GV.UpdatedAt = response.hasOwnProperty('updated_at') ? response.updated_at : ''
    this.UserType = response.hasOwnProperty('user_type') ? response.user_type : ''
    this.Entity = response.hasOwnProperty('entity_name') ? response.entity_name : ''
  }

  pageCalculation(Obj: string | any[]) {
    let toPage = 0
    let page = 0
    let fromPage = 0
    let totalPages = 0
    toPage = Math.min(
      (page + 1) * ENUMS.ITEMS_PER_PAGE,
      Obj.length
    );
    fromPage = page * ENUMS.ITEMS_PER_PAGE;
    totalPages = Math.ceil(
      Obj.length / ENUMS.ITEMS_PER_PAGE
    );
    return { toPage, page, fromPage, totalPages }
  }

  nextPage(val: any, page: any, Obj: string | any[]) {
    let fromPage = (page + val) * ENUMS.ITEMS_PER_PAGE;
    let toPage = Math.min(
      (page + val + 1) * ENUMS.ITEMS_PER_PAGE,
      Obj.length
    )
    page = page + val;

    return { fromPage, toPage, page }
  }

  prevPage(val: number, page: number, Obj: string | any[]) {
    page = page - val;
    let toPage = Math.min(
      (page + val) * ENUMS.ITEMS_PER_PAGE,
      Obj.length
    )
    let fromPage = page * ENUMS.ITEMS_PER_PAGE;
    return { fromPage, toPage, page }
  }

  downloadExcelReport(txnsObj: any, fileName: string, time: string) {
    var ws = XLSX.utils.json_to_sheet(txnsObj);
    var wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    var fileExtenction = '.xlsx';
    var data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName + "-" + time + fileExtenction)
  }

  downloadCsvReport(txnsObj: any, fileName: string, time: string) {
    var ws = XLSX.utils.json_to_sheet(txnsObj);
    var wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var buffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    var fileType = 'csv;'
    var data: Blob = new Blob([buffer], { type: fileType });
    let fileExtenction = '.csv';
    saveAs(data, fileName + "-" + time + fileExtenction)
  }

  validateEmail(value: any): boolean {
    let format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

}

import { Component } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { ENUMS } from 'src/app/ENUMS';
import { ApiService } from 'src/app/_service/api.service';
import { AuthService } from 'src/app/_service/keycloakAuth.service';
import { UtilityService } from 'src/app/_service/utility.service';

@Component({
  selector: 'app-microservices',
  templateUrl: './microservices.page.html',
  styleUrls: ['./microservices.page.scss'],
})
export class MicroservicesPage {
  allMicroserviceObj: any;
  loading: boolean = false;
  microserviceObj: any;
  isShownSearchbar: boolean = false;
  fromPage = ""
  constructor(
    private modalCtrl: ModalController,
    public utilityService: UtilityService,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private apiService: ApiService,
    private authService: AuthService,
    private navParams: NavParams
  ) {

    this.fromPage = this.navParams.get('fromPage')
    this.utilityService.mainMenu = "endpoints"
    this.microserviceList()
  }

  goBack() {
    this.utilityService.mainMenu = "home"
    this.modalCtrl.dismiss();
  }

  showSearchBar() {
    this.isShownSearchbar = !this.isShownSearchbar;
  }

  getItems(ev: any) {
    let val = ev.target.value;
    this.microserviceObj = this.allMicroserviceObj;
    if (val && val.trim() !== '') {
      this.microserviceObj = this.allMicroserviceObj.filter((item: { name: string; description: string; }) => {
        return item.name.toLowerCase().includes(val.toLowerCase()) || item.description.toLowerCase().includes(val.toLowerCase());
      });
    } else {
      this.microserviceObj = this.allMicroserviceObj;
    }
  }

  select(item: any) {
    let navigationExtras: NavigationExtras = {
      state: {
        microservice: item
      }
    }
    this.navCtrl.navigateForward('/endpoint', navigationExtras)
    this.modalCtrl.dismiss();
  }

  async microserviceList() {
    this.allMicroserviceObj = []
    this.loading = true;
    let ld = await this.loadingCtrl.create(ENUMS.LoaderConfig);
    ld.present().then(() => {
      this.apiService.microserviceList()
        .then((data: { data: string; }) => {
          const response = JSON.parse(data.data)
          if (response.status !== 'success') {
            this.loading = false;
            this.utilityService.showErrMsgTop(response.Message);
            return;
          }
          ld.dismiss();
          this.loading = false;
          if (response?.data == undefined) {
            this.modalCtrl.dismiss('createMicroServices');
            return;
          }
          this.allMicroserviceObj = response.data
          this.microserviceObj = this.allMicroserviceObj
        }, (err: any) => {
          ld.dismiss();
          this.loading = false;
          let error = JSON.parse(err.data)
          if (error?.error?.errors?.errorCode == "err-auth-token-is-invalid") {
            this.utilityService.showErrMsgTop('Session timed out! Please login again')
            this.utilityService.showMenu = false
            this.authService.logout()
            return
          }
        });
    });
  }

}

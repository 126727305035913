import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-json-view',
  templateUrl: './json-view.page.html',
  styleUrls: ['./json-view.page.scss'],
})
export class JsonViewPage implements OnInit {
  endpoint: any;
  from: any;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.endpoint = this.navParams.get('endpoint')
    this.from = this.navParams.get('from')
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}

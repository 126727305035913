import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { GV } from '../globalVariable';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router) { }

    canActivate() {
        if (GV.isSession == 'false') {
            this.router.navigate(['/login'])
            return false
        }
        return true
    }
}


@Injectable({ providedIn: 'root' })
export class InitGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate() {
        if (GV.isSession == 'false') {
            return true
        } else {
            this.router.navigate(['/home'])
            return false
        }
    }
}
